var serialize=function(obj) {
  var str=[];
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
}


var api={
  async request(method, url, data) {
    var methodArgs={method, cache: 'no-cache'};
    if (method=="GET" || method=="DELETE") {
      if (data)
        url+="?"+serialize(data);
    }
    var dataArgs={};
    if (method!="GET" && method!="DELETE") {
      dataArgs.headers={
        'Content-Type': "application/json",
      };
      dataArgs.body=JSON.stringify(data);
    }
    try {
      var result=await fetch(url, {...methodArgs, ...dataArgs});
      if (!result.ok)
        return null;
      return (await result.json());
    } catch(e) {
      console.log(e);
    }
    return null;
  },
  async analyze(file) {
    return await this.request("POST", "/api/analyze", {
      id: file.id,
    });
  },
  async start(file) {
    return await this.request("POST", "/api/start", {
      id: file.id,
    });
  },
  async list() {
    return await this.request("GET", "/api/list");
  },
}



export default api;
