<template>
<div class="details">
  <div class="name" v-if="file">
    <label>ID:</label>
    <span>{{ file.id }}</span>
  </div>
  <div class="name" v-if="file">
    <label>File name:</label>
    <span>{{ file.name }} </span>
  </div>
  <div class="name" v-if="file">
    <label>File size:</label>
    <span>{{ formatSize(file.size) }} </span>
  </div>
  <div class="name" v-if="details">
    <label>Format:</label>
    <span>{{ details.format || "(not a video file)" }} </span>
  </div>
  <div class="name" v-if="details && details.format">
    <label>Duration:</label>
    <span>{{ details.duration }}s</span>
  </div>
  <div class="name" v-if="details && details.format">
    <label>Tracks:</label>
    <ul>
      <li v-for="(track, ti) in details.tracks">
        <div class="track">{{ ti }}: {{ track.type }}</div>
        <div><label>Codec:</label><span>{{ track.codec }}</span></div>
        <div v-if="track.fps"><label>FPS:</label><span>{{ track.fps }}</span></div>
        <div v-if="track.width"><label>Size:</label><span>{{ track.width }} x {{ track.height }}</span></div>
      </li>
    </ul>
  </div>
  <button v-if="details && details.format">Start recompress</button>
</div>
</template>

<script>
import api from './api';
export default {
  data: ()=>({
    file: null,
    details: null,
  }),
  methods: {
    formatSize(value) {
      if (value<1024)
        return value+" B";
      value/=1024;
      if (value<1024)
        return value.toFixed(0)+" kB";
      value/=1024;
      if (value<1024)
        return value.toFixed(2)+" MB";
      value/=1024;
      return value.toFixed(2)+" GB";
    },
  },
  watch: {
    async file() {
      if (this.file) {
        this.details=null;
//         this.details=await api.analyze(this.file);
      } else
        this.details=null;
    },
  },
  mounted() {
    window.appFileDetails=this;
  },
}
</script>
