<template>
<header>
  <h1>Recompress</h1>
</header>
<section class="upload-form">
  <file-details />
</section>
<section class="upload-progress">
  <recompress-progress />
</section>
<footer>
</footer>
</template>

<script>
export default {
  methods: {
  }
}
</script>
