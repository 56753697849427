<template>
<ul class="file-list">
  <li v-for="file in files" :class="{done: file.done}">
    <span class="name">{{ file.file.name }}</span>    
    <span class="progress">{{ progress(file) }}</span>
    <span class="size">{{ formatSize(file.file.size) }}</span>
<!--     <a href="" v-on:click.prevent="select(file)">Select</a> -->
  </li>
</ul>
</template>
<script>
import api from './api';

export default {
  data: ()=>({
    files: [],
  }),
  methods: {
    formatSize(value) {
      if (value<1024)
        return value+" B";
      value/=1024;
      if (value<1024)
        return value.toFixed(0)+" kB";
      value/=1024;
      if (value<1024)
        return value.toFixed(2)+" MB";
      value/=1024;
      return value.toFixed(2)+" GB";
    },
    updateProgress(item) {
      if (!this.files.find(a=>a.id==item.id))
        this.files.push({...item});
      var file=this.files.find(a=>a.id==item.id);
      file.progress=item.progress;
      file.done=item.done;
    },
    progress(item) {
      if (item.done)
        return "done";
      if (!item.recompress)
        return "unknown";
      var result=[];
      for(var qualLevel of item.recompress.qualities) {
        if (qualLevel.level.indexOf('preview')==0)
          continue;
        result.push(qualLevel.level+': '+Math.floor(qualLevel.progress/item.recompress.details.duration*100)+"%");
      }
      return result.join(", ");
    },
    select(item) {
//       appFileDetails.file=item;
    },
    async loadFiles(item) {
      this.files=(await api.list()).map(a=>({...a, file: a}));
    },
  },
  mounted() {
    window.appUploadProgress=this;
    this.loadFiles();
    setInterval(()=>this.loadFiles(), 2000);
  },
}
</script>
